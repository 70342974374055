jQuery(function() {
	initMobileNav();
	initAccordion();
	initSlickCarousel();
	initSelect();
	initFancybox();
	initAos();
	initStickyScrollBlock();
});

// initialize fixed blocks on scroll
function initStickyScrollBlock() {
	jQuery('.filter-btns').stickyScrollBlock({
		setBoxHeight: false,
		activeClass: 'fixed',
		container: '.filter-wrap',
		positionType: 'fixed',
		extraTop: function() {
			var totalHeight = 0;
			jQuery('0').each(function() {
				totalHeight += jQuery(this).outerHeight();
			});
			return totalHeight;
		}
	});
}



function initAos() {
	AOS.init({
		once: true,
		duration: 1000,
		disable: window.innerWidth < 768
	});
}

// lightbox init
function initFancybox() {
	jQuery('a.lightbox, [data-fancybox]').fancybox({
		parentEl: 'body',
		margin: [50, 0]
	});
}


function initSelect() {
	jQuery('.custom').niceSelect();
}

function wynist_ajax_filter( _data, _offset = 0 ){
	$.ajax({
		url: ajaxurl,
		data: {
			action: 'wynist_product_filter',
			filters: _data,
			offset: _offset,
		},
		type: 'POST',
		beforeSend: function(){
			$('body').addClass('loading');
		},
		success: function( data ) {
			$('body').removeClass('loading');
			$('.news-block .content').html(data)
		}
	});
}

function wynist_ajax_filter1( _data, _offset = 0 ){
	$.ajax({
		url: ajaxurl,
		data: {
			action: 'wynist_product_filter1',
			filters: _data,
			offset: _offset,
		},
		type: 'POST',
		beforeSend: function(){
			$('body').addClass('loading');
		},
		success: function( data ) {
			$('body').removeClass('loading');
			$('.job-block .content').html(data)
		}
	});
}

$(document).ready(function(){
	$('.filter-btns input[type="radio"], .filter-btns .filter-reset').change(function(){
		var _value = $(this).parents('form').serializeArray();
		wynist_ajax_filter( _value )
	});

	$('.select-form').change(function(){
		console.log('change');
		var _value = $(this).parents('form').serializeArray();
		wynist_ajax_filter1( _value )
	});

	jQuery('.filter-btns input[type="radio"]').change(function() {
		jQuery('.filter-btns').find('.filter-reset').removeClass('active');
	});
	// $('.content').on('click','.page-numbers',function(evt){
	// 	evt.preventDefault();
	// 	var _this = $(this);
	// 	var _value = $('form.filter-form').serializeArray();
	// 	wynist_ajax_filter( _value, _offset );
	// 	evt.stopPropagation();
	// });



	jQuery('.filter-btns .filter-reset').click(function(e) {
		e.preventDefault();
		var self = jQuery(this);
		jQuery('.filter-btns').find('input[type="radio"]').prop('checked',false).trigger('change');
		self.addClass('active');
	});
});






// content tabs init
function initTabs() {
	ResponsiveHelper.addRange({
		'768..': {
			on: function() {
				jQuery('.tabset').tabset({
					tabLinks: 'a',
					defaultTab: true
				});
			},
			off: function() {
				jQuery('.tabset').tabset('destroy');
			}
		}
	});
}


// slick init
function initSlickCarousel() {
	var win = jQuery(window);
	jQuery('.tab-slider').each(function() {
		var holder = jQuery(this);
		var holderClasses = holder.attr('class');
		var holderHtml = holder.html();
		var mode = null;
		holder.on('init', function(e, slick) {
			slick.slickGoTo(1, true);
		});
		var refresh = function() {
			switch(mode) {
				case 'desktop':
				holder.attr('class',holderClasses)
				.html(holderHtml);
				break;
				case 'mobile':
				holder.slick({
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					mobileFirst: true,
					dots: true,
					arrows: false,
				});
				break;
			}
		};
		var resizeHandler = function() {
			if (window.innerWidth > 767) {
				if (mode !== 'desktop') {
					mode = 'desktop';
					refresh();
				}
			} else {
				if (mode !== 'mobile') {
					mode = 'mobile';
					refresh();
				}
			}
		};
		win.on('resize orientationchange', resizeHandler);
		resizeHandler();
	});

	var win = jQuery(window);
	jQuery('.career-slider').each(function() {
		var holder = jQuery(this);
		var holderClasses = holder.attr('class');
		var holderHtml = holder.html();
		var mode = null;
		holder.on('init', function(e, slick) {
			slick.slickGoTo(1, true);
		});
		var refresh = function() {
			switch(mode) {
				case 'desktop':
				holder.attr('class',holderClasses)
				.html(holderHtml);
				break;
				case 'mobile':
				holder.slick({
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					mobileFirst: true,
					dots: true,
					arrows: false,
				});
				break;
			}
		};
		var resizeHandler = function() {
			if (window.innerWidth > 767) {
				if (mode !== 'desktop') {
					mode = 'desktop';
					refresh();
				}
			} else {
				if (mode !== 'mobile') {
					mode = 'mobile';
					refresh();
				}
			}
		};
		win.on('resize orientationchange', resizeHandler);
		resizeHandler();
	});

	jQuery('.carousel').slick({
		slidesToScroll: 1,
		rows: 0,
		arrows: false,
		dots: true,
		mobileFirst: true,
		dotsClass: 'slick-dots'
	});

	jQuery('.slider').slick({
		slidesToScroll: 1,
		rows: 0,
		arrows: false,
		dots: true,
		mobileFirst: true,
		dotsClass: 'slick-dots',
		prevArrow: '<button class="slick-prev"><span class="icon-arrow"></span></button>',
		nextArrow: '<button class="slick-next"><span class="icon-arrow"></span></button>',
		arrows: true,
		responsive: [
		{
			breakpoint: 479,
			settings: {
				slidesToShow: 2,
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 1023,
			settings: {
				slidesToShow: 4,
			}
		}
		]
	});
}



// mobile menu init
function initMobileNav() {
	jQuery('body').mobileNav({
		menuActiveClass: 'nav-active',
		menuOpener: '.nav-opener',
		hideOnClickOutside: true,
		menuDrop: '.nav-drop'
	});
	
}


// accordion menu init
function initAccordion() {
	jQuery('.accordion').slideAccordion({
		opener: '.opener',
		slider: '.slide',
		animSpeed: 300
	});
}
